export default function monCompte(){

    let user_profile = document.querySelector('.user-profile')
    // let sidebar_menu = document.getElementById('sidebarMenu')
    let input_sidebar = document.querySelector('.mobile-menu > input')

    let connection_form = document.querySelector('.connection-form')
    // let close_connection_form = document.querySelector('.connection-form .big-close-cross')
    let close_connection_form = document.querySelector('.connection-form .big-close-cross')
    let login_overlay = document.querySelector('.login-overlay')
    let connection_btn = document.querySelector('.connection-btn')
    let connection_link = Array.from(document.querySelectorAll('.connection-link'))
    let login_error = document.querySelector('#login-error');

    user_profile.addEventListener('click', e =>{
        
      input_sidebar.checked = false
      
    })

    if(connection_btn != null){
      let promise1 = new Promise(function(resolve, reject) {
        connection_btn.addEventListener('click', (evt)=>{
          connection_form.classList.remove('close')
          login_overlay.classList.add('close')
          resolve(connection_form)
          reject(Error('oops...'))
        })
      });
      promise1.then(function(result) {
        let connection_form_close_btn = document.querySelector('.connection-form img')
        connection_form_close_btn.addEventListener('click', ()=>{
          connection_form.classList.add('close')
        })
      });
    }
  

  // big_close_cross.forEach(item => {
  //   item.addEventListener('click', (evt)=>{
  //   login_overlay.classList.add('close');
  //   })
  // })

  if(user_profile != null){
    
    user_profile.addEventListener('click', (evt)=>{
      
      let big_close_cross = Array.from(document.querySelectorAll('.big-close-cross'))
      console.log(big_close_cross);

      big_close_cross.forEach(item => {
        item.addEventListener('click', (evt)=>{
        login_overlay.classList.add('close');
        })
      })
      
      if(connection_form.classList.contains('close')){
        login_overlay.classList.toggle('close');
      }else{
        connection_form.classList.toggle('close');
      }
      
      if(!login_overlay.classList.contains('close')){
        let connection_btn_mobile = document.querySelector('.login-overlay .connection-btn')
        if(connection_btn_mobile != null){
          let promise = new Promise(function(resolve, reject) {
            connection_btn_mobile.addEventListener('click', (evt)=>{
              connection_form.classList.remove('close')
              login_overlay.classList.add('close')
              resolve(connection_form)
              reject(Error('oops...'))
            })
          });
          
          promise.then(function(result) {
            let connection_form_close_btn = document.querySelector('.connection-form img')
            connection_form_close_btn.addEventListener('click', ()=>{
              connection_form.classList.add('close')
            })
          });
          
          
          big_close_cross.forEach(item => {
            item.addEventListener('click', (evt)=>{
              console.log(evt);
            login_overlay.classList.add('close');
            })
          })
        }
      }
    })
  }

  // if(!connection_form.classList.contains('close')){
  //   connection_form_close_btn.addEventListener('click', ()=>{
  //     connection_form.classList.add('close')
  //   })
  // }

  if(connection_link != null){
    connection_link.forEach( (cl) => {
      cl.addEventListener('click', ()=>{
        connection_form.classList.toggle('close')
      })
    })
    close_connection_form.addEventListener('click', ()=>{
      connection_form.classList.add('close')
    })
  }

    var currentUrl = window.location.href;
  if (currentUrl.match("connect=false$")) {
      connection_form.classList.toggle('close');
      login_error.style.display = "block";
  } else {
      login_error.style.display = "none";
  }
}