export default function srollHideMenu(){
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
    let scrollmenu = document.getElementById('scrollmenu')
    let mobile_menu = document.getElementById('mobile-menu')
    let sidebar_menu = document.getElementById('sidebarMenu')
    let currentScrollPos = window.pageYOffset;

    // if ((prevScrollpos > currentScrollPos) && (currentScrollPos > 102)) {
    if (prevScrollpos > currentScrollPos) {
      if (mobile_menu != null){
        mobile_menu.style.top = "0";
        sidebar_menu.style.left = "0";
      }
      if(scrollmenu != null){
        scrollmenu.style.top = "0";
        scrollmenu.style.backgroundColor = "rgba(13, 89, 115, 0.9)";
      }
      if (currentScrollPos < 102 ){
        if(scrollmenu != null){
          scrollmenu.style.backgroundColor = "rgba(125,125,125, 0)";
        }
      }
    } else if (currentScrollPos < 102 ){
      mobile_menu.style.backgroundColor = "rgba(125,125,125, 0)";
      // if(scrollmenu != null){
        //   scrollmenu.style.backgroundColor = "rgba(125,125,125, 0)";
        // }
      } else {
        if (mobile_menu != null){
        mobile_menu.style.backgroundColor = "rgba(125,125,125, 1)";
        // mobile_menu.style.backgroundColor = "rgba(13,89,115, 0.85)";
        mobile_menu.style.top = "-50px";
        sidebar_menu.style.left = "-100%";
      }
      if(scrollmenu != null){
        scrollmenu.style.top = "-102px";
        mobile_menu.style.backgroundColor = "rgba(125,125,125, 0)";
      }
    }
    prevScrollpos = currentScrollPos;
  }

  if(prevScrollpos < 102){
    document.getElementById("scrollmenu").style.backgroundColor = "rgba(125,125,125, 0)";
  }
}