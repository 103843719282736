import "slick-carousel";
import scrollHideMenu from './scrollHideMenu';
import toggleSubMenu from './toggleSubMenu';
import labelTransform from './labelTransform';
import dropdownSearch from './dropdownSearch';
import monCompte from './monCompte';
import EditProfil from './edit-profil';
import requiredSelect from './requiredSelect';
import customSelect from './vendor/customSelect';
import Xhr from './xhr/xhr';

$(function () {
    customSelect();
    Xhr();
    if (document.querySelector('.required-select') !== null) {
        requiredSelect();
    }
    scrollHideMenu();
    toggleSubMenu();
    dropdownSearch();
    labelTransform();
    monCompte();

    if (document.querySelector('.edit-profil')) {
        const editProfil = new EditProfil;
        editProfil.init();
    }


});

$(document).ready(function () {
    $(".slider").slick({
        arrows: false,
        dots: true,
        respondTo: 'slider',
        variableWidth: false,
    });

    $(".carousel").slick({
        dots: true,
    });


    $(".activites-container").slick(
        {
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: true,
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1920,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        }
    );

    $(".carousel-product").slick({
        arrows: false,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    });


    // $('.carousel-gamme').slick({
    //   dots: true,
    //   arrows: true,
    //   infinite: false,
    //   speed: 300,
    //   slidesToShow: 4,
    //   slidesToScroll: 3,
    //   // centerMode: true,
    //   variableWidth: true,

    //   responsive: [
    //     {
    //       breakpoint: 1440,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 2,
    //         infinite: true,
    //         dots: true
    //       }
    //     },
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1
    //       }
    //     },
    //     {
    //       breakpoint: 768,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         arrows: true,
    //         dots: false
    //       }
    //     }
    //   ]
    // });

    $(".carousel-gamme").slick(
        {
            arrows: true,
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            // centerMode: true,
            // autoplay: true,
            // autoplaySpeed: 2000,

            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 668,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    );


    $(".carousel-produits-associes").slick(
        {
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            // centerMode: true,
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        }
    );

    $(".carousel-recettes").slick(
        {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        }
    );
});
