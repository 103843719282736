export default function CustomSelect(){

  var x, i, j, selElmnt, a, b, c;
  /*look for any elements with the class "custom-select":*/
  x = document.getElementsByClassName("custom-select");
  for (i = 0; i < x.length; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    /*for each element, create a new DIV that will act as the selected item:*/
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /*for each element, create a new DIV that will contain the option list:*/
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 0; j < selElmnt.length; j++) {
      /*for each option in the original select element,
      create a new DIV that will act as an option item:*/
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.setAttribute('data-value', selElmnt.options[j].value);
      /*c.addEventListener("click", function(e) {
          //when an item is clicked, update the original select box,
          //and the selected item:
          if(e.target && e.target.nodeName == "DIV") {
          var y, i, k, s, h;
          s = e.target.parentNode.parentNode.getElementsByTagName("select")[0];
          h = e.target.parentNode.previousSibling;

          for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML == e.target.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = e.target.innerHTML;
              y = e.target.parentNode.getElementsByClassName("same-as-selected");
              for (k = 0; k < y.length; k++) {
                y[k].removeAttribute("class");
              }
              e.target.setAttribute("class", "same-as-selected");
              break;
            }
          }
          h.click();
          }
      });*/
      b.appendChild(c);
    }
    x[i].appendChild(b);
    x[i].addEventListener("click", function(e) {
        /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
        if(e.target && e.target.nodeName == "DIV") {
            e.stopPropagation();
            closeAllSelect(e.target);
            if (e.target.nextSibling)
                e.target.nextSibling.classList.toggle("select-hide");
            e.target.classList.toggle("select-arrow-active");

            if (e.target.parentNode.classList.contains("select-items")) {
                    var y, i, k, s, h;
                    s = e.target.parentNode.parentNode.getElementsByTagName("select")[0];
                    h = e.target.parentNode.previousSibling;

                    for (i = 0; i < s.length; i++) {
                        if (s.options[i].value == e.target.getAttribute('data-value')) {
                            s.selectedIndex = i;
                            h.innerHTML = e.target.innerHTML;
                            y = e.target.parentNode.getElementsByClassName("same-as-selected");
                            for (k = 0; k < y.length; k++) {
                                y[k].removeAttribute("class");
                            }
                            e.target.setAttribute("class", "same-as-selected");
                            if (e.target.nextSibling)
                                e.target.nextSibling.removeAttribute("class");
                            break;
                        }
                    }
                   //h.click();

               // closeAllSelect(e.target);
            }
        }
      });
  }
  function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
    except the current select box:*/
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }
  /*if the user clicks anywhere outside the select box,
  then close all select boxes:*/
  document.addEventListener("click", closeAllSelect);

}
