export default function toggleSubMenu(){

  let has_children = Array.from(document.querySelectorAll('#mobile-menu .menu-item-has-children'))

  has_children.map(item =>{
    item.addEventListener('click', e =>{
      // let submenu = document.querySelector('')
      console.log(item)
      if(item.lastElementChild.style.display === 'none' || !(item.lastElementChild.hasAttribute('style')) ){
        item.lastElementChild.style.display = 'block'
      }else{
        item.lastElementChild.style.display = 'none'
      }
    })
  })
  
}