export default class EditProfil {
	constructor () {
        this.$editBtn = document.querySelector('.edit-profil')
        this.$cancelBtn = document.querySelector('.annuler')
        this.$infos = document.querySelector('.infos-profil-container');
        this.$form = document.querySelector('.infos-profil-form')
        this.$editNom = document.querySelector('#edit-nom')
    }
	
	init () {
    this._handle_editBtn()
    this._handle_cancelBtn()
	}
	
	_handle_editBtn () {
    this.$editBtn.addEventListener('click', () =>{
      this.$form.classList.toggle('close')
      this.$infos.classList.toggle('close')
      this.$editNom.focus()
    })

        var currentUrl = window.location.href;

    /*
        if (currentUrl.match("error=alreadyexist")) {
            this.$editBtn.click();

            var form = document.getElementById("edituser");
            var errorChild = document.createElement('div');

            errorChild.innerHTML = "L'email rensseigné précédemment existe déjà, veuillez en choisir une autre";
            errorChild.style.color = "red";
            errorChild.style.textAlign = "center";

            form.insertBefore(errorChild, document.getElementById("iprofil"));
        }
        */
    }

    _handle_cancelBtn () {
        this.$cancelBtn.addEventListener('click', () =>{
            this.$form.classList.toggle('close')
            this.$infos.classList.toggle('close')
        })
    }
}