export default function requiredSelect(){

    let all_required_selects = Array.from(document.querySelectorAll('.required-select'))

    all_required_selects.map(required_select =>{
      let selected = required_select.querySelector('.select-selected')
      selected.addEventListener('click', e =>{
        let same_as_selected = required_select.querySelector('.same-as-selected')
        console.log(same_as_selected)
        if(same_as_selected !== null){
          selected.classList.add('asterix-none')
        }
        
      })
    })

}