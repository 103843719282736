export default function Xhr() {
    $('.delete-selection-user-pdf').on("click", function (e) {
        e.preventDefault();
        $.ajax({
            url: '../wp-admin/admin-ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                action: 'delete_selection',
            }
        });
    });

    $('#addselection').on("click", function (e) {
        e.preventDefault();
        $.ajax({
            url: '../../wp-admin/admin-ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                id: $('body').attr('class'),
                action: 'add_to_selection',
            }
        }).done(function (data) {
            if (data['code'] == "ok") {
                $("#txt-selection").html(data['message']);
            }
        });
    });

    $('#select-filter-product').on('change', function (e) {
        $('#sort-filter-product').submit();
    })

    $('#triProduct').on("click", function (e) {
        var tri = $(this).find(".select-selected")[0].innerHTML;
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[0];

        $.ajax({
            url: baseUrl + 'wp-admin/admin-ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                tri: tri,
                action: 'tri_product',
                bclass: $('body').attr('class')
            }
        }).done(function (data) {
            $("#lproducts").html(data);
        });
    });

    $('body').on("click", ".selectarg", function (e) {
        e.preventDefault();

        var link = $(this).attr('href')
        var elem = $(this);

        $.ajax({
            url: '../../wp-admin/admin-ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                id: $(this).attr('data-id'),
                class: $(this).attr('class'),
                action: 'add_arg',
            },
            beforeSend: function (data) {
                elem.find(".loadselect").show();
            }
        }).done(function (data) {
            elem.find(".loadselect").hide();
            elem.find("div > span").html(data["message"]);
        });
    });

    $('body').on("click", ".delete-item-selection-argu", function (e) {
       e.preventDefault();
        var ids = [];
        $(".sa input[type='checkbox']:checked").each(function () {
            ids.push($(this).attr("data-id"));
        });

        $.ajax({
            url: '../../wp-admin/admin-ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                id: $(this).attr('data-id'),
                selected: ids,
                action: 'remove_to_selection_compte_argu',
            }
        }).done(function (data) {
            if (data['html'] == undefined) {
                $("#selectheadargu").hide();
                $("#selectbtngroupeargu").hide();
                $("#tabselecargu").html("Aucun produit dans ma sélection");
            } else {
                $("#tabselecargu").html(data["html"]);
            }
        });
    });

    $('body').on("click", ".delete-item-selection", function (e) {
       e.preventDefault();

        var ids = [];
        $(".sp input[type='checkbox']:checked").each(function () {
            ids.push($(this).attr("data-id"));
        });

        $.ajax({
            url: '../../wp-admin/admin-ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                id: $(this).attr('data-id'),
                selected: ids,
                action: 'remove_to_selection_compte',
            }
        }).done(function (data) {
            if (data['html'] == undefined) {
                $("#selecthead").hide();
                $("#selectbtngroupe").hide();
                $("#tabselec").html("Aucun produit dans ma sélection");
            } else {
                $("#tabselec").html(data["html"]);
            }
        });
    });

    $('body').on("click", "#removeselec-argu", function (e) {
        e.preventDefault();

        $(".sa input[type='checkbox']:checked").each(function () {
            $(this).prop("checked", false);
        });
    });

    $('body').on("click", "#removeselec", function (e) {
        e.preventDefault();
        $(".sp input[type='checkbox']:checked").each(function () {
            $(this).prop("checked", false);
        });
    });

    $("#uiemail").on("keydown", function (e) {
        var elemError = $(this).parent().next();

        if (elemError) {
            elemError.hide();
        }
    });

    $(".custom-select").on("click", function (e) {
        var besoin, gamme;

        $("select[name='besoin']").each(function () {
            if ($(this).val() != 0) {
                besoin = $(this).val();
            }
        });

        $("select[name='gamme']").each(function () {
            if ($(this).val() != 0) {
                gamme = $(this).val();
            }
        });

        if ($(this).attr('id') == "selectlabel") {
            return false;
        }

        if ($(this).attr('id') == "triProduct") {
            return false;
        }

        if (e.target.parentNode.classList.contains("select-items")) {
            $.ajax({
                url: '../../wp-admin/admin-ajax.php',
                type: 'POST',
                dataType: 'json',
                data: {
                    besoin: besoin,
                    gamme: gamme,
                    action: 'search_update_label'
                },
                beforeSend: function (data) {
                    $("#selectlabel .select-selected").css("opacity", "0.5");
                    $("#loadlabel").show();
                }
            }).done(function (data) {
                $("#loadlabel").hide();
                if (!data.length) {
                    $("#selectlabel").html("");
                    $("#selectlabel").hide();
                } else {
                    $("#selectlabel").html(data);
                    $("#selectlabel").show();
                }
            });
        }

    });

    $('body').on("click", "#downloadselec-argu", function (e) {
        e.preventDefault(e);

        var ids = [];
        $(".sa input[type='checkbox']:checked").each(function () {
            ids.push($(this).attr("data-id"));
        });

        $.ajax({
            url: '../../wp-admin/admin-ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                arrId: ids,
                action: "export_pdf_argu"
            },
            beforeSend: function (data) {
                $("#dloadargu").show();
            }
        }).done(function (data) {
            $("#dloadargu").hide();
            if (data['url']) {
                //document.location = data['url'];
                window.open(data['url'], "_blank");
            }
        });
    });

    $('body').on("click", "#downloadselec", function (e) {
        e.preventDefault();

        var ids = [];
        $(".sp input[type='checkbox']:checked").each(function () {
            ids.push($(this).attr("data-id"));
        });

        $.ajax({
            url: '../../wp-admin/admin-ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                arrId: ids,
                action: "export_pdf"
            },
            beforeSend: function (data) {
                $("#dload").show();
            }
        }).done(function (data) {
            $("#dload").hide();
            if (data['url']) {
                //document.location = data['url'];
                window.open(data['url'], "_blank");
            }
        });
    });

    $('body').on("click", "#downloadambiances", function (e) {
        e.preventDefault();

        $.ajax({
            url: '../../wp-admin/admin-ajax.php',
            type: 'POST',
            dataType: 'json',
            data: {
                action: 'dlzip',
                id: $("body").attr("class")
            }
        }).done(function (data) {
            location.href = data;
        });
    });
}
