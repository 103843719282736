export default function dropdownSearch(){

  let mobile_search_dropdown = document.querySelector('.search-mobile .listing-search-dropdown');
  let mobile_search_icon_p = document.querySelector('.search-mobile .listing-search-dropdown p');
  let mobile_close_cross = document.querySelector('.search-mobile .listing-search-dropdown .mobile-close-cross');
  let mobile_form = document.querySelector('.search-mobile .search-form')

  let search_dropdown = document.querySelector('.search-desktop .listing-search-dropdown');
  let search_icon_p = document.querySelector('.search-desktop .listing-search-dropdown p');
  let close_cross = document.querySelector('.search-desktop .listing-search-dropdown .close-cross');
  let form = document.querySelector('.search-desktop .search-form')

  if(search_dropdown != null){
    search_dropdown.addEventListener('click', e =>{
      form.classList.toggle('close')
      form.classList.contains('close') ? close_cross.classList.add('close') :  close_cross.classList.remove('close')
    })

    // close_cross.addEventListener('click', e =>{
    //   console.log('click');
    //   mobile_form.classList.add('close')
    //   form.classList.add('close')
    //   close_cross.classList.add('close');

    // })
  }
  if(mobile_search_dropdown != null){
      mobile_search_dropdown.addEventListener('click', e =>{
      mobile_form.classList.toggle('close')
      mobile_form.classList.contains('close') ? mobile_close_cross.classList.add('close') : mobile_close_cross.classList.remove('close')
    })

    // mobile_close_cross.addEventListener('click', e =>{
    //   console.log('click');
    //   mobile_form.classList.add('close')
    //   form.classList.add('close')
    //   mobile_close_cross.classList.add('close');
    // })
  }
}