export default function labelTransform () {

  let labels = document.querySelectorAll('.forminator-label')
  let inputs = document.querySelectorAll('.forminator-field')

  if (labels) {

    labels.forEach(el => {
      el.addEventListener('click', (evt) => {
        const current_input = evt.target.nextElementSibling
        current_input && current_input.focus()
      })
    })

    inputs.forEach(el => {
      const current_label = el.querySelector('.forminator-label')
      const current_input = el.querySelector('.forminator-input')
        || el.querySelector('.forminator-textarea')

      if (!current_input) return false

      el.addEventListener('input', (evt) => {
        if (current_input.value !== '') {
          current_label.style.display = "none"
        }
        else {
          current_label.style.display = "block"
        }
        current_label.style.top = "10px"
        current_label.style.color = "#26a69a"
        current_label.style.fontSize = "11px"
        current_label.style.transition = "all 0.5s"
      })

      el.addEventListener('focusin', (evt) => {
        current_label.style.display = "block"
        current_label.style.top = "10px"
        current_label.style.color = "#26a69a"
        current_label.style.fontSize = "11px"
        current_label.style.transition = "all 0.5s"
      })

      el.addEventListener('focusout', (evt) => {
        current_label.style.display = "none"
        if (current_input.value === '') {
          current_label.style.display = "block"
          current_label.style.color = "#B7B7B7"
          current_label.style.top = "20px"
          current_label.style.fontSize = "14px"
          current_label.style.transition = "all 0.5s"
        }
      })
    })

  }
}
